import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;
export async function fetcher({ url, data, method, baseURL, }) {
    const config = {
        url,
        method,
        data,
        ...(baseURL ? { baseURL } : {}),
    };
    const response = await axios(config);
    return response.data;
}
export default fetcher;
